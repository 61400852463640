import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Divider, Breadcrumbs } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import { Animation } from '@components/Animation';
import HomeSections from '@components/HomeSections/HomeSections';
import Layout from '@components/Layout';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SubHeader from '@components/SubHeader/SubHeader';
import { MyPageContext } from '@types';
import { detectDeviceIsMobile, detectDeviceIsTablet } from '@utils';

const breadcrumbList = [
  {
    icon: 'HOME',
    pathName: '/',
    routeName: 'Home',
  },
];

const Home: MyPageContext = () => {
  const router = useRouter();

  const isMobile = detectDeviceIsMobile();
  const isTablet = detectDeviceIsTablet();
  useEffect(() => {
    const magazineUrl = process.env.EL_MAGAZINE_URL;

    if (magazineUrl) {
      const iframeContainer = document.getElementById('iframe-container');

      if (iframeContainer) {
        const iframe = document.createElement('iframe');
        iframe.src = magazineUrl;

        iframe.style.width = '40%';
        iframe.style.height = '250px';
        iframe.style.border = '1px solid #C5CFD1';
        iframe.style.display = 'none';
        iframe.style.marginTop = '10px';

        // Append the iframe to the container
        iframeContainer.appendChild(iframe);
        return () => {
          if (iframe.parentElement) {
            iframe.parentElement.removeChild(iframe);
          }
        };
      }
    }
    return () => {};
  }, []);
  return (
    <>
      <Animation />
      <SubHeader />

      {!isMobile && (
        <Box sx={{ display: 'flex', width: '100%', height: '50px' }}>
          <Box
            sx={(theme) => ({
              minWidth: isTablet ? '170px' : '264px',
              [theme.breakpoints.up('lg')]: {
                width: 'calc((100vw - 867px)/2)',
                minWidth: '320px',
              },
              [theme.breakpoints.down('md')]: {
                minWidth: '86px',
                width: '86px',
              },
            })}
          />
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: isTablet ? 'calc(100vw - (86px*2))' : '867px' }}>
            <Breadcrumbs
              routeList={breadcrumbList}
              onClick={(pathName: string | undefined) => router.push(pathName || '/')}
            />
          </Box>
        </Box>
      )}
      <Divider />
      <HomeSections />
    </>
  );
};

Home.Layout = Layout;

export default Home;
