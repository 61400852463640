import { useCallback, useContext, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
  Dialog,
  Loader,
  Divider,
  ICourseStructure,
  LearningItemCardList,
  ICollectionLearningItem,
  IUserLearningItemCollection,
  LEARNING_ITEM_COLLECTION_TYPE,
  ICollectionLearningItemsMappings,
} from '@ascd/witsby-components';

import { Box, Container, Grid } from '@mui/material';
import { cloneDeep, get, orderBy, sumBy } from 'lodash';
import { ASCDSection } from '@components/HomeSections/ASCDSection';
import {
  ASCD_ID,
  ADMIN_ROUTES,
  STRUCTURE_TYPE,
  HOME_SECTION_NAME,
  LEARNER_COLLECTION_ROUTES,
} from '@constants';
import { AppContext } from '@contexts/appContext';
import FIND_LEARNING_ITEMS from '@graphql/schema/findLearningItems.graphql';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';
import GET_LEARNING_ITEM_COLLECTION from '@graphql/schema/getLearningItemCollections.graphql';
import LEARNING_ITEM_COLLECTIONS from '@graphql/schema/learningItemCollections.graphql';
import LEARNING_ITEMS from '@graphql/schema/learningItems.graphql';
import LEARNING_ITEMS_MAPPINGS from '@graphql/schema/learningItemsMappings.graphql';
import CREATE_DRAFT_LEARNING_ITEM from '@graphql/schema/mutations/createDraftLearningItem.graphql';
import CREATE_DRAFT_LEARNING_ITEM_COLLECTION from '@graphql/schema/mutations/createDraftLearningItemCollection.graphql';
import LOG_EVENTS from '@graphql/schema/mutations/logEvents.graphql';
import REMOVE_LEARNING_ITEM from '@graphql/schema/mutations/removeLearningItem.graphql';
import UPDATE_LEARNING_ITEM_COLLECTION from '@graphql/schema/mutations/updateLearningItemCollection.graphql';
import UPDATE_USER_TRACK_LEARNING_ITEM from '@graphql/schema/mutations/updateUserLearningItemCollection.graphql';
import USER_LEARNING_ITEM_COLLECTIONS from '@graphql/schema/userLearningItemCollections.graphql';
import { ICourse, IEBook, IFilterCourseList, ILearnerCollectionRoutes } from '@types';
import {
  sleep,
  showToast,
  getUserName,
  getChapters,
  handleGraphqlError,
  detectDeviceIsTablet,
  detectDeviceIsMobile,
} from '@utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomeSections = () => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const isMobile = detectDeviceIsMobile();
  const isTablet = detectDeviceIsTablet();
  const {
    state: { currentUser, currentInstitution },
  } = useContext(AppContext);

  const [id, setId] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUnPublishDialog, setOpenUnPublishDialog] = useState(false);
  const [openDraftEditDialog, setOpenDraftEditDialog] = useState<
    | undefined
    | {
        id: string;
        type: string;
        message: string;
      }
  >(undefined);
  const [course, setCourse] = useState<ICourse | IFilterCourseList | IEBook | null>(null);

  const closeDraftEditModal = () => setOpenDraftEditDialog(undefined);
  const [logEvents] = useMutation(LOG_EVENTS);

  const { loading, data: allLearningItemCollectionList } = useQuery(GET_LEARNING_ITEM_COLLECTION, {
    variables: {
      limit: 8,
      userId: currentUser.oktaId,
      organizationIds: [currentInstitution._id, ASCD_ID],
    },
    fetchPolicy: 'network-only',
  });

  const { loading: userCourseLoading, data: userCourseData } = useQuery(
    USER_LEARNING_ITEM_COLLECTIONS,
    {
      variables: { userId: [currentUser.oktaId] },
      fetchPolicy: 'network-only',
    },
  );

  const strategiesList = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.Strategy',
    [],
  );

  const [removeLearningItem] = useMutation(REMOVE_LEARNING_ITEM, {
    refetchQueries: [
      {
        query: FIND_LEARNING_ITEMS,
        variables: {
          tags: ['Resources'],
          organizationId: [currentInstitution._id, ASCD_ID],
          itemState: 'PUBLISHED',
        },
      },
    ],
  });

  const [updateLearningItemCollection] = useMutation(UPDATE_LEARNING_ITEM_COLLECTION, {
    refetchQueries: [
      { query: USER_LEARNING_ITEM_COLLECTIONS, variables: { userId: [currentUser.oktaId] } },
      {
        query: GET_LEARNING_ITEM_COLLECTION,
        variables: {
          limit: 8,
          userId: currentUser.oktaId,
          organizationIds: [currentInstitution._id, ASCD_ID],
        },
      },
      {
        query: LEARNING_ITEM_COLLECTIONS,
        variables: {
          status: 'PUBLISHED',
          userId: currentUser.oktaId,
          organizationId: currentInstitution._id,
          learningCollectionType: LEARNING_ITEM_COLLECTION_TYPE.USER_LEARNING_LIST,
        },
      },
    ],
  });
  const [createDraftLearningItem, { loading: createDraftLearningItemLoading }] = useMutation(
    CREATE_DRAFT_LEARNING_ITEM,
  );
  const [createDraftLearningItemCollection] = useMutation(CREATE_DRAFT_LEARNING_ITEM_COLLECTION);

  const [updateUserTracking] = useMutation(UPDATE_USER_TRACK_LEARNING_ITEM, {
    refetchQueries: [
      {
        query: LEARNING_ITEM_COLLECTIONS,
        variables: {
          status: 'PUBLISHED',
          userId: currentUser.oktaId,
          organizationId: currentInstitution._id,
          learningCollectionType: LEARNING_ITEM_COLLECTION_TYPE.USER_LEARNING_LIST,
        },
      },
    ],
    onCompleted: ({ updateUserLearningItemCollection }) => {
      const cachedUserTrackingData = apolloClient.readQuery({
        query: USER_LEARNING_ITEM_COLLECTIONS,
        variables: { userId: [currentUser.oktaId] },
      });

      const cloneCachedUserTrackingData: IUserLearningItemCollection[] = cloneDeep(
        cachedUserTrackingData?.userLearningItemCollections || [],
      );
      const findTrackDataIndex = cloneCachedUserTrackingData.findIndex(
        (track) => track._id === updateUserLearningItemCollection._id,
      );
      if (findTrackDataIndex > -1) {
        cloneCachedUserTrackingData[`${findTrackDataIndex}`] = updateUserLearningItemCollection;
      } else {
        cloneCachedUserTrackingData.push(updateUserLearningItemCollection);
      }

      apolloClient.writeQuery({
        variables: { userId: [currentUser.oktaId] },
        query: USER_LEARNING_ITEM_COLLECTIONS,
        data: { userLearningItemCollections: cloneCachedUserTrackingData },
      });
    },
  });

  const userCourseTrackings: IUserLearningItemCollection[] = useMemo(() => {
    if (!userCourseLoading && get(userCourseData, 'userLearningItemCollections.length')) {
      return userCourseData.userLearningItemCollections;
    }
    return [];
  }, [userCourseLoading, userCourseData]);

  let latestReleasesList: ICourse[] = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.AllCourses',
    [],
  );
  let availableCourseList: ICourse[] = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.Courses',
    [],
  );

  const institutionalLearningList: ICourse[] = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.LearningLists',
    [],
  );
  latestReleasesList = orderBy(latestReleasesList, 'publishedAt', 'desc') as ICourse[];

  const latestReleasesCount: number = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.allCount',
    0,
  );

  const availableCourseCount: number = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.CoursesCount',
    0,
  );
  const institutionalLearningListCount: number = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.learningListCount',
    0,
  );
  const strategyCount: number = get(
    allLearningItemCollectionList,
    'getLearningItemCollections.StrategyCount',
    0,
  );

  availableCourseList = orderBy(availableCourseList, 'publishedAt', 'desc') as ICourse[];

  const getLearningItemsMapping = useCallback(
    async (parentId: string, courseId: string) => {
      const response = await apolloClient.query({
        query: LEARNING_ITEMS_MAPPINGS,
        variables: { collectionId: courseId, parentId },
        fetchPolicy: 'network-only',
      });

      if (response?.data?.learningItemsMappings?.length) {
        return response.data.learningItemsMappings;
      }
      return [];
    },
    [apolloClient],
  );

  const getLearningItems = useCallback(
    async (ids: string[]) => {
      const response = await apolloClient.query({
        query: LEARNING_ITEMS,
        variables: { ids },
        fetchPolicy: 'network-only',
      });

      if (response?.data?.learningItems?.length) {
        return response.data.learningItems?.filter(
          (l: ICollectionLearningItem) => l?.isTraceable && l?.itemState === 'PUBLISHED',
        );
      }
      return [];
    },
    [apolloClient],
  );

  const addUserTracking = async (selectedCourse: ICourse) => {
    if (selectedCourse?.totalAllocatedTime) {
      const { firstChapter } = getChapters(selectedCourse?.structure);
      updateUserTracking({
        variables: {
          userName: getUserName(currentUser),
          updateUserLearningItemCollectionInput: {
            learningItemCollectionId: selectedCourse._id,
            userId: currentUser.oktaId,
            type: selectedCourse?.type,
            lastActiveStructureId: firstChapter?.id,
            totalAllocatedTime: selectedCourse?.totalAllocatedTime,
            totalEarnedTime: selectedCourse?.totalAllocatedTime,
          },
        },
      });
    } else {
      let totalAllocatedTime = 0;
      const structureChapters = selectedCourse?.structure?.filter(
        (structure: ICourseStructure) => structure.type === STRUCTURE_TYPE.CHAPTER,
      );
      for await (const chapter of structureChapters) {
        const learningItemsMappings = await getLearningItemsMapping(chapter.id, selectedCourse._id);
        if (learningItemsMappings?.length) {
          const learningItemsId: string[] = learningItemsMappings.map(
            (l: ICollectionLearningItemsMappings) => l.learningItemId,
          );

          const learningItems: ICollectionLearningItem[] = await getLearningItems(learningItemsId);
          if (learningItems?.length) {
            totalAllocatedTime += sumBy(learningItems, 'InstitutionalAllocatedTime');
          }
        }
      }
      const { firstChapter } = getChapters(selectedCourse?.structure);
      updateUserTracking({
        variables: {
          userName: getUserName(currentUser),
          updateUserLearningItemCollectionInput: {
            userId: currentUser.oktaId,
            totalAllocatedTime,
            type: selectedCourse?.type,
            learningItemCollectionId: selectedCourse._id,
            totalEarnedTime: totalAllocatedTime,
            lastActiveStructureId: firstChapter?.id,
          },
        },
      });
    }
  };

  const handleSelectCourse = async (
    item: ICourse | IEBook | IFilterCourseList,
    buttonName?: string,
    isFromCardClicked?: boolean,
  ) => {
    logEvents({
      variables: {
        data: item,
        actionType: 'VIEWED',
        logType: item.type?.replace(' ', '_').toUpperCase(),
      },
    });

    if (item.__typename === 'LearningItem' || item.type === 'Learning Item') {
      router.push(`/learning-item/${item?._id}`);
      return;
    }

    if (!isFromCardClicked && buttonName === 'Start') {
      addUserTracking(item as ICourse);
      await sleep(30);
    }

    router.push(
      `${
        LEARNER_COLLECTION_ROUTES[`${item.type as keyof ILearnerCollectionRoutes}`] ||
        LEARNER_COLLECTION_ROUTES.COURSE
      }/${item._id}`,
    );
  };

  const handleDeleteModal = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };
  const handleUnPublishModal = () => {
    setOpenUnPublishDialog(!openUnPublishDialog);
  };
  const handleEditModal = () => {
    setOpenEditDialog(!openEditDialog);
  };

  const onViewAllItem = (type: string) => {
    if (type === HOME_SECTION_NAME.EBOOKS) {
      router.push('/ebooks?title=Featured+Ebooks');
    } else if (type === HOME_SECTION_NAME.AVAILABLE_COURSES) {
      router.push('/search?tag=Course&title=Courses');
    } else if (type === HOME_SECTION_NAME.RESOURCES_CARD) {
      router.push('/search?tag=Resources&title=Resources');
    } else if (type === HOME_SECTION_NAME.AVAILABLE_LEARNING_LIST) {
      router.push(
        `/search?tag=Institutional+Learning+List&tag=Personal+Learning+List&title=Learning+Lists`,
      );
    } else if (type === HOME_SECTION_NAME.EL_MAGAZINE) {
      router.push('/el-magazine');
    } else if (type === HOME_SECTION_NAME.LIVE_EVENTS) {
      router.push('/live-events');
    } else if (type === HOME_SECTION_NAME.AVAILABLE_RECORDINGS) {
      router.push('https://www.ascd.org/webinars');
    } else if (type === HOME_SECTION_NAME.AVAILABLE_STRATEGIES) {
      router.push('/search?tag=Strategy&title=Strategies');
    } else {
      router.push(`/search?tag=Course&tag=Institutional+Learning+List&title=Latest+Releases`);
    }
  };

  // when click on card create a copy option from actions
  const handleCreateANewCopy = (
    copyId: string | number,
    itemData?: ICourse | IEBook | IFilterCourseList,
  ) => {
    if (itemData?.__typename === 'LearningItem') {
      createDraftLearningItem({
        variables: {
          id: copyId,
          userId: currentUser.oktaId,
          updateRelativeSourceId: true,
          organizationId: currentInstitution._id,
        },
      })
        .then((res) => {
          router.push({
            query: { redirectTo: `${ADMIN_ROUTES.ADMIN}/learning-item/${copyId}` },
            pathname: `${ADMIN_ROUTES.ADMIN}/learning-item/${res?.data?.createDraftLearningItem}`,
          });
        })
        .catch(handleGraphqlError);
    } else {
      createDraftLearningItemCollection({
        variables: {
          id: copyId,
          userId: currentUser.oktaId,
          updateRelativeSource: true,
          organizationId: currentInstitution._id,
        },
      })
        .then((res) => {
          const type =
            itemData?.type === 'COURSE' || itemData?.type === 'STRATEGY'
              ? itemData?.type.toLowerCase()
              : 'learning-list';
          if (get(res, 'data.createDraftLearningItemCollection.message')) {
            setOpenDraftEditDialog({
              ...get(res, 'data.createDraftLearningItemCollection'),
              type,
            });
          } else {
            showToast('Copy created successfully!');
            router.push(
              `${ADMIN_ROUTES.ADMIN}/${type}/${get(
                res,
                'data.createDraftLearningItemCollection.id',
              )}`,
            );
          }
        })
        .catch(handleGraphqlError);
    }
  };

  // After click on yes button of edit modal
  const onEditModal = () => {
    const itemType = course?.type?.toLowerCase() || '';
    const isCourse = itemType === 'course';
    const isInstitutionalLearningList = itemType === 'institutional_learning_list';
    if (course?.__typename === 'LearningItem') {
      /*
        This is to so confirmation for publish learning item
      */
      createDraftLearningItem({
        variables: {
          id: course?._id,
          userId: currentUser.oktaId,
          updateRelativeSourceId: true,
          organizationId: currentInstitution._id,
        },
      })
        .then((res) => {
          router.push({
            query: { redirectTo: `${ADMIN_ROUTES.ADMIN}/learning-item/${course?._id}` },
            pathname: `${ADMIN_ROUTES.ADMIN}/learning-item/${res?.data?.createDraftLearningItem}`,
          });
        })
        .catch(handleGraphqlError);
    }
    if (isCourse || isInstitutionalLearningList) {
      if (course?.relativeSourceId) {
        if (isCourse) {
          router.push(`${ADMIN_ROUTES.ADMIN}/course/${course?.relativeSourceId}`);
        }
        if (isInstitutionalLearningList) {
          router.push(`${ADMIN_ROUTES.ADMIN}/learning-list/${course?.relativeSourceId}`);
        }
      } else {
        if (!course?.type) return;
        createDraftLearningItemCollection({
          variables: {
            id: course?._id,
            userId: currentUser.oktaId,
            updateRelativeSource: true,
            organizationId: currentInstitution._id,
          },
        })
          .then((res) => {
            if (isCourse) {
              router.push(
                `${ADMIN_ROUTES.ADMIN}/course/${res?.data?.createDraftLearningItemCollection}`,
              );
            }
            if (isInstitutionalLearningList) {
              router.push(
                `${ADMIN_ROUTES.ADMIN}/learning-list/${res?.data?.createDraftLearningItemCollection}`,
              );
            }
          })
          .then(() => {
            setOpenEditDialog(false);
            setCourse(null);
          })
          .catch(handleGraphqlError);
      }
    }
  };

  // After click on yes button of delete modal
  const onUnPublishModal = () => {
    if (course?.__typename === 'LearningItem') {
      removeLearningItem({ variables: { id } })
        .then(() => {
          showToast('Unpublished successfully');
          setOpenUnPublishDialog(false);
          setCourse(null);
        })
        .catch(handleGraphqlError);
    } else {
      updateLearningItemCollection({
        variables: {
          userId: currentUser.oktaId,
          organizationId: currentInstitution._id,
          updateLearningItemCollectionInput: {
            _id: id,
            status: 'UNPUBLISHED',
          },
        },
      })
        .then(() => {
          showToast('Unpublished successfully');
          setOpenUnPublishDialog(false);
          setCourse(null);
        })
        .catch(handleGraphqlError);
    }
  };

  // After click on yes button of delete modal
  const onDeleteModal = () => {
    if (course?.__typename === 'LearningItem') {
      removeLearningItem({ variables: { id } })
        .then(() => {
          showToast('Deleted successfully');
          setOpenDeleteDialog(false);
          setCourse(null);
        })
        .catch(handleGraphqlError);
    } else {
      updateLearningItemCollection({
        variables: {
          userId: currentUser.oktaId,
          organizationId: currentInstitution._id,
          updateLearningItemCollectionInput: {
            _id: id,
            status: 'ARCHIVED',
          },
        },
      })
        .then(() => {
          showToast('Deleted successfully');
          setOpenDeleteDialog(false);
          setCourse(null);
        })
        .catch(handleGraphqlError);
    }
  };
  // when click on card unPublish option from actions
  const handleCourseUnPublishOperation = (
    courseId: string | number,
    itemData?: ICourse | IEBook | IFilterCourseList,
  ) => {
    handleUnPublishModal();
    setId(courseId as string);
    setCourse(itemData || null);
  };

  // when click on card delete option from actions
  const handleCourseDeleteOperation = (
    courseId: string | number,
    itemData?: ICourse | IEBook | IFilterCourseList,
  ) => {
    handleDeleteModal();
    setId(courseId as string);
    setCourse(itemData || null);
  };

  const fetchImage = useCallback(
    async (imageName: string) => {
      const MINUTE_MS = 60000;
      const defaultCacheImages = localStorage.getItem('images');
      let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
      if (localStorageImages[`${imageName}`]) return localStorageImages[`${imageName}`].image;
      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      const image = response?.data?.getImageUrl?.url || '';
      const currentTime = new Date();
      localStorageImages = {
        ...localStorageImages,
        [imageName]: {
          image,
          expiry: currentTime.getTime() + MINUTE_MS * 14,
        },
      };

      localStorage.setItem('images', JSON.stringify(localStorageImages));
      return image;
    },
    [apolloClient],
  );

  return (
    <>
      {openUnPublishDialog && (
        <Dialog
          open
          isDelete
          onYesClick={onUnPublishModal}
          onNoClick={handleUnPublishModal}
          handleClose={handleUnPublishModal}
          content="Are you sure you want to unpublish this?"
        />
      )}
      {openDeleteDialog && (
        <Dialog
          open
          isDelete
          onYesClick={onDeleteModal}
          onNoClick={handleDeleteModal}
          handleClose={handleDeleteModal}
          content="Are you sure you want to delete this?"
        />
      )}
      {openEditDialog && (
        <Dialog
          open
          isDelete
          onYesClick={onEditModal}
          onNoClick={handleEditModal}
          handleClose={handleEditModal}
          loading={createDraftLearningItemLoading}
          content="Are you sure you want to edit this?"
        />
      )}
      {openDraftEditDialog && (
        <Dialog
          open
          isDelete
          onNoClick={closeDraftEditModal}
          handleClose={closeDraftEditModal}
          loading={createDraftLearningItemLoading}
          content={openDraftEditDialog.message}
          onYesClick={() => {
            router.push(
              `${ADMIN_ROUTES.ADMIN}/${openDraftEditDialog.type}/${openDraftEditDialog.id}`,
            );
          }}
        />
      )}
      <Container
        maxWidth={false}
        sx={(theme) => ({
          pb: '2rem',
          px: '0.688rem',
          height: loading ? 'calc(100vh - 60px)' : 'auto',
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
          },
        })}>
        {loading ? (
          <Loader isFullScreen />
        ) : (
          <>
            <Box // In future will be added side bar
              sx={(theme) => ({
                width: isTablet ? '75px ' : 'calc((100vw - 900px)/2)',

                [theme.breakpoints.up('lg')]: {
                  minWidth: '305px',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  minWidth: '264px',
                },
                [theme.breakpoints.down('md')]: {
                  minWidth: '75px',
                },
                [theme.breakpoints.down('sm')]: {
                  width: '0px',
                  minWidth: '0px',
                },
              })}
            />

            <Box
              sx={(theme) => ({
                width: isTablet ? 'calc(100vw - (86px*2))' : '867px',
                [theme.breakpoints.up('lg')]: {
                  minWidth: '867px',
                },
                [theme.breakpoints.down('lg')]: {
                  minWidth: '590px',
                },
                [theme.breakpoints.down('sm')]: {
                  minWidth: '95vw',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  mt: '12px',
                },
                '& .css-aprhon .css-hsr2rx': {
                  p: '0',
                },
                '& .slick-disabled': { opacity: 0 },
                '& .slick-track': {
                  marginLeft: '0px',
                },
                paddingRight: '22px',
              })}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12} md={12} xl={12} lg={12} data-testid="latest-releases">
                  <LearningItemCardList
                    loading={loading}
                    isCardClickable
                    defaultExpanded
                    btnTitle="View All"
                    title="Latest Releases"
                    fetchImage={fetchImage}
                    count={latestReleasesCount}
                    courseData={latestReleasesList}
                    handleSelectCourse={(
                      learningItemData?: ICourse | IEBook | IFilterCourseList,
                      buttonName?: string,
                      isFromCardClicked?: boolean,
                    ) =>
                      handleSelectCourse(
                        learningItemData as ICourse | IEBook | IFilterCourseList,
                        buttonName,
                        isFromCardClicked,
                      )
                    }
                    userCourseTrackings={userCourseTrackings}
                    sectionTitle={HOME_SECTION_NAME.LATEST_RELEASES}
                    handleViewAll={() => onViewAllItem('latestReleases')}
                    currentUser={currentUser}
                    handleUnPublish={handleCourseUnPublishOperation}
                    handleDelete={handleCourseDeleteOperation}
                    // handleEdit={handleCourseEditOperation}
                    handleCreateCopy={handleCreateANewCopy}
                    organizationId={currentInstitution._id}
                  />
                </Grid>
                {!isMobile && <Divider />}
                <Grid data-testid="courses" item xs={6} sm={12} md={12} xl={12} lg={12}>
                  <LearningItemCardList
                    loading={loading}
                    isCardClickable
                    defaultExpanded
                    btnTitle="View All"
                    fetchImage={fetchImage}
                    count={availableCourseCount}
                    title="Courses"
                    courseData={availableCourseList}
                    handleSelectCourse={(
                      learningItemData?: ICourse | IEBook | IFilterCourseList,
                      buttonName?: string,
                      isFromCardClicked?: boolean,
                    ) =>
                      handleSelectCourse(
                        learningItemData as ICourse | IEBook | IFilterCourseList,
                        buttonName,
                        isFromCardClicked,
                      )
                    }
                    userCourseTrackings={userCourseTrackings}
                    sectionTitle={HOME_SECTION_NAME.AVAILABLE_COURSES}
                    handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.AVAILABLE_COURSES)}
                    currentUser={currentUser}
                    handleUnPublish={handleCourseUnPublishOperation}
                    handleDelete={handleCourseDeleteOperation}
                    // handleEdit={handleCourseEditOperation}
                    handleCreateCopy={handleCreateANewCopy}
                    organizationId={currentInstitution._id}
                  />
                </Grid>
                {!isMobile && <Divider />}
                <Grid data-testid="learningList" item xs={6} sm={12} md={12} xl={12} lg={12}>
                  <LearningItemCardList
                    loading={loading}
                    isCardClickable
                    courseData={institutionalLearningList}
                    fetchImage={fetchImage}
                    title="Learning Lists"
                    count={institutionalLearningListCount}
                    handleSelectCourse={(
                      learningItemData?: ICourse | IEBook | IFilterCourseList,
                      buttonName?: string,
                      isFromCardClicked?: boolean,
                    ) =>
                      handleSelectCourse(
                        learningItemData as ICourse | IEBook | IFilterCourseList,
                        buttonName,
                        isFromCardClicked,
                      )
                    }
                    userCourseTrackings={userCourseTrackings}
                    sectionTitle={HOME_SECTION_NAME.AVAILABLE_LEARNING_LIST}
                    handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.AVAILABLE_LEARNING_LIST)}
                    btnTitle="view all"
                    currentUser={currentUser}
                    handleUnPublish={handleCourseUnPublishOperation}
                    handleDelete={handleCourseDeleteOperation}
                    // handleEdit={handleCourseEditOperation}
                    handleCreateCopy={handleCreateANewCopy}
                    organizationId={currentInstitution._id}
                  />
                </Grid>
                {!isMobile && <Divider sx={{ width: '100%' }} />}
                <Grid data-testid="strategies" item xs={6} sm={12} md={12} xl={12} lg={12}>
                  <LearningItemCardList
                    loading={loading}
                    isCardClickable
                    hideProgress
                    isDateVisible
                    defaultExpanded
                    fetchImage={fetchImage}
                    title="Strategies"
                    btnTitle="View All"
                    courseData={strategiesList}
                    count={strategyCount}
                    handleSelectCourse={(
                      learningItemData?: ICourse | IEBook | IFilterCourseList,
                      buttonName?: string,
                      isFromCardClicked?: boolean,
                    ) =>
                      handleSelectCourse(
                        learningItemData as ICourse | IEBook | IFilterCourseList,
                        buttonName,
                        isFromCardClicked,
                      )
                    }
                    userCourseTrackings={userCourseTrackings}
                    sectionTitle={HOME_SECTION_NAME.AVAILABLE_STRATEGIES}
                    handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.AVAILABLE_STRATEGIES)}
                    currentUser={currentUser}
                    handleUnPublish={handleCourseUnPublishOperation}
                    handleDelete={handleCourseDeleteOperation}
                    // handleEdit={handleCourseEditOperation}
                    handleCreateCopy={handleCreateANewCopy}
                    organizationId={currentInstitution._id}
                  />
                </Grid>
                {!isMobile && <Divider />}
                <ASCDSection onViewAllItem={onViewAllItem} />
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default HomeSections;
